<template>
  <div v-if="club" class="container-fluid">
    <b-card>
      <div class="wrapper">
        <div class="mt-2 mb-3">
          <b-row class="split-layout">
            <b-col :cols="getCols()">
              <complex
                :validation="validation"
                :club="club"
                @on:reload-club="$emit('on:reload-club')"
              />
            </b-col>
            <div class="split-layout__divider">
              <div class="split-layout__rule"></div>
              <div class="split-layout__rule"></div>
            </div>
            <b-col v-if="getCols() === '6'">
              <contacts
                :validation="validation"
                :cols="getCols()"
                :club="club"
                @on:reload-club="$emit('on:reload-club')"
              />
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
    <b-card v-if="getCols() === '12'">
      <div class="wrapper">
        <div class="mb-3">
          <b-row class="split-layout">
            <b-col>
              <contacts
                :validation="validation"
                :cols="getCols()"
                :club="club"
                @on:reload-club="$emit('on:reload-club')"
              />
            </b-col>
          </b-row>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    validation: require('@validation/entities/doinsport/Club.json')
  }),
  components: {
    Complex: () => import('./Complex'),
    Contacts: () => import('./Contacts')
  },
  props: {
    club: {
      type: Object,
      default: () => {
      }
    },
    classes: {
      type: String,
      default: 'ml-4'
    },
  },
  beforeMount() {
    this.$store.dispatch('validator/set', this.validation);
  },
  methods: {
    getCols() {
      const width = this.$store.getters["layout/getInnerWidth"];

      if (width < 1368) {
        return '12';
      }
      return '6';
    }
  },
}
</script>
<style scoped>
.card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 20px #C4C8D066;
  border-radius: 8px;
  opacity: 1;
}

.card .card-body {
  padding-top: 12px;
  padding-left: 35px;
  padding-right: 25px;
  padding-bottom: 12px;
  background-color: transparent;
}

.card .card-header {
  background-color: #fff;
  padding-top: 5px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 5px;
  border-bottom: unset;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
</style>
